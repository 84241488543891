var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("green-container", [
    _c("div", { staticClass: "seoJobList" }, [
      _c(
        "div",
        { staticClass: "seoJobList__wrap" },
        [
          _c(
            "accordion",
            { attrs: { items: _vm.accordionItems, "item-label": "title" } },
            [
              _vm._l(_vm.accordionItems, function (accordionItem) {
                return _c("template", { slot: accordionItem.title }, [
                  _c(
                    "div",
                    { staticClass: "seoJobList__grid" },
                    _vm._l(accordionItem.group, function (item) {
                      return _c(
                        "ul",
                        { staticClass: "seoJobList__list" },
                        _vm._l(item.jobs, function (job) {
                          return _c(
                            "li",
                            { staticClass: "seoJobList__item" },
                            [
                              _c("link-wrap", { attrs: { link: job.link } }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(job.name) +
                                    "\n                                "
                                ),
                              ]),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }