








































import {asArray} from '@labor-digital/helferlein/lib/FormatAndConvert/asArray';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {forEach} from '@labor-digital/helferlein/lib/Lists/forEach';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {State} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import GreenContainer from '../../../../Component/Layout/GreenContainer/GreenContainer.vue';
import Accordion from '../../../../Component/Misc/Accordion/Accordion.vue';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'SeoJobsList',
    components: {Accordion, LinkWrap, GreenContainer},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        // Build structure array
        const structure = [];
        let row = [];
        for (let i = 0; i < 26; i++) {
            row.push({
                char: String.fromCharCode(97 + i),
                jobs: []
            });
            if (row.length === 3) {
                structure.push(row);
                row = [];
            }
        }
        structure.push(row);

        return {
            structure: structure
        };
    },
    computed: {
        accordionItems(): Array<PlainObject>
        {
            const result = [];

            forEach(this.jobsStructured, (group) => {
                result.push({
                    title: group[0].group,
                    group: group
                });
            });

            return result;
        },
        jobsStructured(): Array<PlainObject>
        {
            const result = this.structure;
            const jobs = this.jobs;

            forEach(result, (group, groupIndex) => {
                let groupName = '';

                forEach(group, (item, charIndex) => {
                    groupName += item.char;

                    forEach(jobs, (job: State, jobIndex) => {
                        if (item.char.toUpperCase() === job.get('name').charAt(0).toUpperCase()) {
                            result[groupIndex][charIndex].jobs.push(job.getAll());
                        }
                    });
                });

                // Add group name
                forEach(group, (item, charIndex) => {
                    result[groupIndex][charIndex].group = groupName;
                });
            });

            return result;
        },
        jobs(): Array<State>
        {

            return asArray(this.context.initialState.getAll());
        }
    }
};
